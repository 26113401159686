<template>
  <b-modal id="import-csv" size="md" title="Upload Produce" hide-footer>
     <b-row>
        <b-container>
          <h4 class="dark font-weight-bold">STEP 1</h4>
          <h5 class="dark font-weight-light">
            Download CSV from template
          </h5>
          <download-excel  class="btn btn-primary" :data="json_data" :fields="json_fields" type="csv"
           v-analytics:click="['download_produce_template',{element:'Produce'}]"
           name="addProduce.xls">
            <b-icon icon="download" aria-hidden="true"></b-icon> Download template
          </download-excel>
          <hr />
          <h4 class="dark font-weight-bold">STEP 2</h4>
          <h5 class="dark font-weight-light">Open the template with Excel, Fill the form columns with produce details and save the file</h5>
          <hr />
          <h4 class="dark font-weight-bold">STEP 3</h4>
          <h5 class="dark font-weight-light">Upload the .csv file</h5>
          <div>
          <hr/>
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            accept=".csv"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            v-analytics:change="['attach_produce_csv',{element:'Produce'}]"
          ></b-form-file>
          <hr/>
            <b-button squared variant="success" class="mr-1" @click="submitFile" v-show="file">
             <b-icon icon="upload" aria-hidden="true" v-show="!isLoading"></b-icon>
             <b-spinner v-if="isLoading" small label="Small Spinner"></b-spinner>
            Upload File
          </b-button>
          </div>
        </b-container>
    </b-row>
  </b-modal>
</template>
<script>

import { axiosApiInstance } from "@/plugins/axios";
import { getCurrentSeason } from "@/components/add_produce/actions.js";

export default {
  name: "add-csv",
  data() {
    return {
      baseURI: process.env.VUE_APP_BACKEND,
      file: null,
      isLoading: false,
      isLarge: false,
      json_data: [{}],
      json_fields: {
      "Supplier Type": "supplier_type",
      Supplier: "supplier",
      Contact: "contact",
      District: "district",
      Crop: "value_chain",
      Variety: "variety",
      "Gross Weight": "gross_weight",
      "Net Weight": "net_weight",
      "Unit Price": "unit_price",
      "Moisture": "moisture_content",
      "Foreign Matter": "foreign_matter",
      "Registered First Name": "reg_fname",
      "Registered Last Name": "reg_lname",
      "Registration Number": "reg_number",
      "Amount used": "amount_used",
      "Payment method":'mobile',
      "Payment Info Present" : "paymentInfoPresent",
      "Payment Info Updated": "paymentInfoUpdated",
      "Mobile": "method"
    },
      form: {
        project_id: null,
        season: getCurrentSeason()
    },
    error: null
    };
  },
  methods: {
    uploadFile() {
      this.file = this.$refs.file.files[0];
    },
    onReset() {
      this.file = null;
      this.$bvModal.hide('import-csv');
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    submitFile() {
      let self = this;
      let formData = new FormData();
      formData.append('file', this.file);
      this.isLoading=true
      axiosApiInstance.post(this.baseURI + '/api/v3/produce/upload-produce', formData, {headers: {'Content-Type': 'multipart/form-data'}}
      ).then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            this.onReset();
            self.$vToastify.success("Produce successfully uploaded");
            this.isLoading = false;
          } else if (!result.data.success) {
            this.isLoading = false;
            this.error = result.data.message;
            self.$vToastify.error("Failed to add produce.Record already exists");
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.error = error;
          self.$vToastify.error("Something went wrong please try again");
        })
    }
  },
  computed: {
    projectId(){
      return this.$store.state.project_id;
    }
  }
};
</script>

