
<style scoped>
.hide {
  display: none;
}
.price-text {
  font-size: 24px;
  font-weight: 700;
}
.calendar {
  margin-left: 30%;
}

.produce-progress {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  /* box-shadow: 0 0 8px rgba(0,0,0,0.1); */
  margin-bottom: 8px;
}
</style>

<template>
  <div class="pl-1">
    <b-navbar>
      <b-navbar-brand class="page-title">PRODUCE</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button
          v-b-modal.import-csv
          squared
          variant="outline-primary"
          class="mr-1"
          v-analytics:click="['import_produce_csv', { element: 'Produce' }]"
          :disabled="!can('modify', 'produce')"
        >
          <b-icon-plus></b-icon-plus> Import produce from csv
        </b-button>
        <b-button
          v-if="can('view', 'settings')"
          squared
          variant="outline-primary"
          class="mr-2"
          @click="routePage"
        >
          <b-icon-gear></b-icon-gear> Produce Settings
        </b-button>
        <b-button
          v-if="can('modify', 'produce') && !loading"
          v-b-modal.add-produce
          v-analytics:click="['add_new_produce', { element: 'Produce' }]"
          squared
          variant="primary"
        >
          <b-icon-plus></b-icon-plus> Record Produce
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <b-row class="m-0">
        <b-col>
          <div class="d-flex">
            <b-select
              text="All Produce"
              class="mr-2 form-select"
              v-analytics:change="['filter_by_produce', { element: 'Produce' }]"
              v-model="criteria.selectedCrop"
            >
              <b-select-option selected value="">All Produce</b-select-option>
              <b-select-option
                v-for="(crop, idx) in value_chains"
                :key="idx"
                :value="crop"
                >{{ crop }}</b-select-option
              >
            </b-select>
            <b-select
              text="All Suppliers"
              class="mx-1 form-select"
              v-model="criteria.supplierType"
              v-analytics:change="[
                'filter_produce_supplier',
                { element: 'Produce' },
              ]"
            >
              <b-select-option selected value="">All Suppliers</b-select-option>
              <b-select-option
                v-for="(supplier, idx) in suppliers"
                :key="idx"
                :value="supplier"
                >{{ supplier }}</b-select-option
              >
            </b-select>
            <!-- <b-select
              v-model="selectedPeriod"
              class="form-select"
              @change="filterByDate"
            >
              <b-select-option selected value="">All Time</b-select-option>
              <b-select-option value="Last Week"> Last Week </b-select-option>
              <b-select-option value="This Month"> This Month </b-select-option>
              <b-select-option value="Last Month"> Last Month </b-select-option>
              <b-select-option value="Last Quater">
                Last Quater
              </b-select-option>
              <b-select-option value="This Year"> This Year </b-select-option>
              <b-select-option value="Custom Range">
                Custom Range
              </b-select-option>
            </b-select> -->
            <produce-date-picker
              class="mx-2"
              @dates-selected="cfilterByDate"
            />
            <b-form class="ml-auto">
              <b-form-input
                placeholder="Search Name"
                type="search"
                class="search"
                v-analytics:keyup="[
                  'search_produce_name',
                  { element: 'Produce' },
                ]"
                v-model="criteria.searchfield"
              ></b-form-input>
            </b-form>
          </div>
        </b-col>
        <b-col cols="auto">
          <div class="sidebar-width text-right" v-if="loading">Loading...</div>
          <div class="sidebar-width text-right" v-if="!loading">
            <button
              @click="reload"
              v-analytics:click="[
                'refresh_produce_page',
                { element: 'Produce' },
              ]"
              class="btn-opt primary"
            >
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button
              v-print="'#ProduceTable'"
              class="btn-opt primary"
              v-analytics:click="['print_produce', { element: 'Produce' }]"
            >
              <b-icon-printer></b-icon-printer> Print
            </button>
            <download-excel
              class="btn btn-opt primary"
              :data="formatDates(items)"
              v-analytics:click="['export_produce_csv', { element: 'Produce' }]"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row class="m-0">
      <b-col class="pt-2">
        <b-table
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          id="ProduceTable"
          class="text-capitalize"
          @row-clicked="row_click"
          :filter="criteria"
          :filter-function="filterItems"
          @filtered="updateRows"
        >
          <template #cell(name)="data">
            <b-avatar icon="person">{{ data.value[0] }}</b-avatar>
            {{ data.value }}
          </template>
          <template #cell(supplier_type)="data">
            <usertype-pill :user_type="data.item.supplier_type">
            </usertype-pill>
          </template>
          <template #cell(quantity)="data">
            {{ data.value.toLocaleString() }}
          </template>
          <template #cell(amount)="data">
            {{ data.value.toLocaleString() }}
          </template>
          <template #cell(date)="data">
            {{ data.value | formatDate }}
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="advance-records"
          class="b-pagination-class"
        ></b-pagination>
      </b-col>
      <b-col cols="auto">
        <!-- <div class="sidebar pt-2">
          <div class="py-2">
            <h6 class="subtitle m-0">COLLECTION STATS</h6>
            <span>Jan 2020 - Dec 2020</span>
          </div>
          <div class="produce-progress"
            :variant="produce.variant"
            v-for="(produce,idx) in produces"
            :key="'p-' + idx"
            show
          >
            <span>
              <b>{{ produce.name }}</b
              >: {{produce.actual.toLocaleString()}} / {{produce.target.toLocaleString()}}
            </span>
            <span class="text-right">{{ pct(produce) }}</span>
            <b-progress
              :value="pct(produce)"
              variant="success"
            ></b-progress>
          </div>
          <b-link>View More <b-icon-arrow-bar-right></b-icon-arrow-bar-right></b-link>
          <div class="pt-4">
            <h6 class="subtitle">PENDING PAYMENTS</h6>
            <h2 class="price-text">UGX {{totalPendingAmount.toLocaleString()}}</h2>
          </div>

          <b-button variant="success-" to="/payments/" squared class=" btn btn-success">Open Payments</b-button>
        </div> -->
      </b-col>
    </b-row>
    <add-produce
      :modal_id="modal_id"
      :key="'record'+model_key"
      @BulkSuccessful="onBulkSuccess"
    ></add-produce>
    <add-csv></add-csv>
    <farmer-modal @ModalClose="reload"></farmer-modal>
    <loader :isLoading="loading" />
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import AddProduce from "@/components/add_produce/AddProduce.vue";
import UsertypePill from "./components/usertype_pill/UsertypePill.vue";

import FarmerModal from "@/components/farmer_modal/Farmer.vue";
import AddCsv from "@/components/add_csv/AddCsv.vue";
import { can } from "@/modules/auth/utils.js";
import ProduceDatePicker from "./components/ProduceDatePicker.vue";
import dateMixin from "./date_mixin";
import {
  onBulkSuccess,
  onFarmerClick,
  onRowClick,
  formatDate,
  formatDates,
  fetchData,
  routePage,
  fetchStats,
  Periods,
} from "./action.js";
import moment from "moment";

export default {
  name: "Produce",
  components: { Loader, AddProduce, FarmerModal, AddCsv, ProduceDatePicker, UsertypePill, },
  mixins:[dateMixin],
  filters: {
    formatDate,
  },
  data() {
    let fields = [
      { key: "name" },
      {
          key: "supplier_type",
          label: "",
      },
      "produce",
      
      { key: "quantity", label: "Quantity (kg)" },
      { key: "amount", tdClass: "text-right fw-bold", thClass: "text-right" },
      "date",
    ];
    let value_chains = [];
    let suppliers = ["Farmer", "Farmer Group", "Agent"];
    let items = [];

    return {
      fields,
      items,
      value_chains,
      suppliers,
      season: [],
      loading: false,
      selectedPeriod: "",
      baseURI: process.env.VUE_APP_BACKEND,
      token: localStorage.getItem("access_token"),
      perPage: 10,
      currentPage: 1,
      totalPendingAmount: 0,
      modal_id: "add-produce",
      model_key:0,
      rows: 0,
      criteria: {
        selectedPeriod: Periods[""],
        supplierType: "",
        searchfield: "",
        selectedCrop: "",
      },
    };
  },
  mounted: function () {
    this.fetchData(this);
    this.fetchStats(this);
    this.$analytics.track("open_produce", { element: "Produce" });
    this.dateInit();
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
    url() {
      return `${this.baseURI}/api/v3${this.$store.state.url_prefix}/produce`;
    },
  },
  methods: {
    can,
    formatDates,
    reload() {
      this.fetchData(this);
      this.fetchStats(this);
      this.model_key++;
    },
    row_click(item) {
      this.$analytics.track("click_produce_supplier", { element: "Produce" });
      onRowClick(item, this);
    },
    filterItems(row, criteria) {
      let filters = [];
      if (criteria["selectedPeriod"]) {
        filters.push(
          moment(row["date"], "YYYY-MM-DD").isBetween(
            ...this.criteria.selectedPeriod
          )
        );
      }
      if (criteria["supplierType"]) {
        filters.push(row["supplier_type"] == criteria["supplierType"]);
      }
      if (criteria["selectedCrop"]) {
        filters.push(
          row["produce"].toLowerCase() == criteria["selectedCrop"].toLowerCase()
        );
      }
      if (criteria["searchfield"]) {
        filters.push(
          row["name"]
            .toLowerCase()
            .includes(criteria["searchfield"].toLowerCase())
        );
      }
      return !filters.includes(false);
    },
    updateRows(_, count) {
      this.rows = count;
    },
    onBulkSuccess,
    onFarmerClick,
    fetchData,
    routePage,
    fetchStats,
  },
};
</script>
<style scoped>
.btn-success {
  color: #fff !important;
}
</style>